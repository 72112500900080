body {
    padding:0;
    margin:0;
    font-family: 'Open Sans', sans-serif;
}

.font_righteous {
    font-family: 'Righteous', cursive;
}

/*====================
    GENERAL CONSTANTS
======================*/


.bck_black {
    background: #3c3c3c;
}
.bck_white {
    background: #ffffff;
}
.bck_red {
    background: #0d0d0e;
}
.bck_yellow {
    background: #0d0d0e;
}

/*====================
        HEADER
======================*/


header {
    padding:10px 0px;
    transition: all 300ms ease-in;
}

header .header_logo {
    flex-grow: 1;
}
header .header_logo_venue {
    font-size: 30px;
}

header .header_logo_title {
    text-transform: uppercase;
}

/*====================
        QUESTIONS
======================*/
#question {
    margin-top: 100px;
    background-color: #0d0d0e;
    color: white;
    padding: 10px;
    text-align: center;
}

#question h4{
    background-color: #fff;
    color: #0b0e21;
    display:inline-block;
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 600;
}

#question p{
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 2px;
    padding-left: 50px;
    padding-right: 50px;
}

/*====================
        ANSWERS
======================*/

#answers ul{
    list-style-type: none;
    padding: 0;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#answers li {
    background-color: #fff;
    border: 2px solid #0d0d0e;
    min-height: 70px;
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    cursor: pointer;
}

#answers li span {
    background-color: #0d0d0e;
    color: #fff;
    font-size: 30px;
    flex: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .3s ease;
}

#answers li p {
    color: #0094da;
    font-size: 16px;
    font-weight: 600;
    flex: calc(100% - 75px);
    margin: auto 20px;
    transition: color .3s ease;
}

#answers li:after{
    display: block;
}

#answers li:hover {
    transform: scale(1.03);
}

#answers li.right {
    border-color: #4CAF50;
    color: #fff;
    background-color: #4CAF50;
}

#answers li.right span {
    background-color: #4CAF50;
}

#answers li.right p {
    color: #fff;   
}

#answers li.wrong {
    border-color: #dc0a0a;
    color: #dc0a0a;
    background-color: #dc0a0a;
    animation: shake 1.0s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

#answers li.wrong span {
    background-color: #dc0a0a;
}

#answers li.wrong p {
    color: #fff;
    background-color: #dc0a0a;
}

#submit {
    text-align: center;
    margin: 20px 0;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
}
}

.fancy-btn {
    border: 2px solid #0d0d0e;
    border-radius: 0;
    background-color: #0d0d0e;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 75px;
    margin: 0 auto;
    text-transform: uppercase;
    transition: color .2s ease, background-color .2s ease;
}

.fancy-btn:hover {
    background-color: #fff;
    color: #0b0e21;
}


/*====================
        POPUP CSS
======================*/


.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup {
    text-align: center;
    background-color: #F8f7f4;
    border-radius: 10px;
    overflow: hidden;
}

.popup h1 {
    background-color: #0d0d0e;
    color: #F8f7f4;
    border-bottom: 1px solid #F8f7f4;
    padding: 20px;
    margin-top: 0;
}

.popup p {
    font-size: 18px;
    letter-spacing: 1px;
    margin: 5px;
}



/*====================
        FOOTER
======================*/

footer {
    padding:50px 0px;
    text-align: center;
    font-size: 60px;
    color:#F8f7f4;
    background: #0d0d0e;
}

footer .footer_copyright {
    font-size: 18px;
}

.footer_copyright a{
    text-decoration: none;
    color:#0094da;
}