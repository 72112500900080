
@media screen and (max-width: 800px) {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    h1 {
        font-size: 15px;
    }
}
@media screen and (min-width: 801px) {
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    h1 {
        font-size: 25px;
    }
}